import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import {AuthenticationGuard} from "@core/authentication/authentication.guard";
import {BackendService} from "@core/backendService/backend.service";

const routes: Routes = [
  {
    path: 'dashboard/participant',
    // @ts-ignore
    loadChildren: () => import('./pages/user/user-pages.module').then(m => m.UserPagesModule),
    canActivate: [AuthenticationGuard],
  },
  { path: 'dashboard/admin',
    // @ts-ignore
    loadChildren: () => import('./pages/admin/admin-pages.module').then(m => m.AdminPagesModule),
    canActivate: [AuthenticationGuard],
  },
  { path: 'dashboard/collaborator',
    // @ts-ignore
    loadChildren: () => import('./pages/collaborator/collaborator-pages.module').then(m => m.CollaboratorPagesModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: '',
    // @ts-ignore
    loadChildren: () => import('./pages/public/public-pages.module').then(m => m.PublicPagesModule),
  },
  // Fallback when no prior route is matched
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "top",
      // scrollPositionRestoration: "disabled",
      // scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
