import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import '@angular/common/locales/global/it';
import {CoreModule} from "@core/core.module";
import { PixelModule } from 'ngx-pixel';
import {NgxCookiebotModule, NgxCookiebotConfig} from "@halloverden/ngx-cookiebot";


export class CookiebotConfig extends NgxCookiebotConfig {
  blockingMode = 'auto'
  cbId ='249f82e9-7bb6-4486-833a-c4ea946f9f0c'
}


@NgModule({
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot(),
    PixelModule.forRoot({ pixelId: '0'}),
    environment.production ? NgxCookiebotModule.forRoot(CookiebotConfig): [],
    // NgxCookiebotModule.forRoot(CookiebotConfig),
    ShellModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
