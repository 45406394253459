import {HostListener, Injectable} from '@angular/core';
import {PixelService} from "ngx-pixel";
import {filter, map, shareReplay, take, tap} from "rxjs/operators";
import {BackendService} from "@core/backendService/backend.service";
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject} from "rxjs";
import {PixelEventName, PixelEventProperties} from "ngx-pixel/lib/pixel.models";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AuthenticationService} from "@core/authentication/authentication.service";
import {Credentials, CredentialsService} from "@core/authentication/credentials.service";
import {Participant} from "@shared/models/Participant";

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  public facebookPixelAppID: string
  public facebookPixelClickID: string
  public facebookPixelUtmSource?: string

  public facebookPixelAppID$: BehaviorSubject<string>
  public facebookPixelClickID$: BehaviorSubject<string>
  public ready$: Observable<[string, string]>

  public facebookPixelClickID_QueryStringKey = 'fbclid'

  // @HostListener('window:beforeunload', ['$event'])
  // handleBeforeUnload(event:BeforeUnloadEvent) {
  //   sessionStorage.removeItem('facebookPixelClickID')
  // }

  constructor(
    private pixel: PixelService,
    private backendService: BackendService,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    // console.log('INIT FacebookPixelService')

    // Events used to notify when single values are READY
    this.facebookPixelAppID$ = new BehaviorSubject<string>(undefined)
    this.facebookPixelClickID$ = new BehaviorSubject<string>(undefined)

    // Emit READY event only when facebookPixelAppID and facebookPixelClickID are present
    // they are both required to send meaningful pixel events to facebook
    this.ready$ = combineLatest([
      this.facebookPixelClickID$,
      this.facebookPixelAppID$,
    ])
    .pipe(
      filter(([facebookPixelClickID, facebookPixelAppID]:[string, string])=>!!facebookPixelClickID && !!facebookPixelAppID),
      shareReplay(1)
    )


    // Start tracking for values to be ready or change
    // those are also responsible for single value READY event emission
    this.trackFacebookPixelClickID()
    this.trackFacebookPixelAppID()


    // this.authenticationService.authChange$.subscribe(()=>{
    //   console.log('REMOVE PIXEL ID')
    //   this.removeFacebookPixelClickID()
    // })

    // 1) Remove facebook facebookPixelClickID from browser storage to avoid assign this to another user
    // NOTE: Afterwards the facebookPixelClickID will be available in user credentials if the user has registered
    // during the interval of time where facebookPixelClickID was available
    // (EG: after the user landed on the platform from facebook AD link)
    // 2) Set facebookPixelClickID if present in user credentials
    // (EG: After login)
    this.credentialsService.change$.pipe().subscribe((credentials:Credentials)=>{
      if(!!credentials){
        this.removeFacebookPixelClickID()

        if(!!credentials.facebook_pixel_click_id){
          this.facebookPixelClickID = credentials.facebook_pixel_click_id
          this.facebookPixelUtmSource = credentials.facebook_utm_source
          this.facebookPixelClickID$.next(this.facebookPixelClickID)
        }
      }
    })
  }


  private trackFacebookPixelAppID(){
    // Track app global configuration from api available/change
    this.backendService.config$.subscribe(
      (config:any)=>{
        this.facebookPixelAppID = config.FacebookPixelID
        if(!!this.facebookPixelAppID) this.facebookPixelAppID$.next(this.facebookPixelAppID)
      },
      (error)=>{ console.log(error)}
    )
  }

  private trackFacebookPixelClickID(){
    this.facebookPixelClickID = this.credentialsService.credentials?.facebook_pixel_click_id
      || localStorage.getItem('facebookPixelClickID')

    this.facebookPixelUtmSource = this.credentialsService.credentials?.facebook_utm_source
      || localStorage.getItem('facebookPixelUtmSource')

    // Watch facebookPixelClickID_QueryStringKey present on url queryParams
    // Doesnt react to change after the first emission
    this.route.queryParamMap
      .pipe(
        filter((paramMap: ParamMap)=>{
          return !!paramMap.get(this.facebookPixelClickID_QueryStringKey) || !!paramMap.get('utm_source')
        }),
      )
      .subscribe((paramMap: ParamMap) => {
        // Get facebookPixelClickID from url
        this.facebookPixelClickID = paramMap.get(this.facebookPixelClickID_QueryStringKey)
        this.facebookPixelUtmSource = paramMap.get('utm_source')
        console.log(this.facebookPixelClickID, this.facebookPixelUtmSource)

        if(!!this.facebookPixelUtmSource){
          localStorage.setItem('facebookPixelUtmSource', this.facebookPixelUtmSource)
        }
        if(!!this.facebookPixelClickID){
          // Set facebookPixelClickID from url
          localStorage.setItem('facebookPixelClickID', this.facebookPixelClickID)
          // Send facebookPixelClickID presence event
          this.facebookPixelClickID$.next(this.facebookPixelClickID)
        }
      });
  }

  public pixelInitialize(disablePushstate:boolean=true){
    if(this.facebookPixelAppID){
      this.pixel.remove()
      this.pixel.initialize(this.facebookPixelAppID)
    }
    else{
      console.warn('Can\'t initialize Facebook Pixel: facebookPixelAppID not available')
    }

    // Disable PageEvent on every navigation
    if(!!disablePushstate){
      // @ts-ignore
      window.fbq && (window.fbq.disablePushState = true)
    }
  }

  public pixelRemove(){
    this.pixel.remove()
  }

  public removeFacebookPixelClickID(){
    localStorage.removeItem('facebookPixelClickID')
    localStorage.removeItem('facebookPixelUtmSource')

    this.facebookPixelClickID = undefined
    this.facebookPixelUtmSource = undefined
  }

  public userRegistered(extraProperties:{}={}, participant_id?:number){

    this.trackCustom('Signup', {
      'Click ID' : this.facebookPixelClickID,
      'Utm Source' : this.facebookPixelUtmSource,
        ...extraProperties
    })

    if(participant_id) this.pixel_sent(participant_id)
  }

  public track(eventName:PixelEventName, pixelEventProperties:PixelEventProperties={}){
    this.pixel.track(eventName, pixelEventProperties)
  }

  public trackCustom(eventName:string, pixelEventProperties:any={}){
    this.pixel.trackCustom(eventName, pixelEventProperties)
  }

  public pixel_sent(participant_id: number){
    console.log('pixel_sent', participant_id, this.facebookPixelAppID)
    let request = this.backendService.pixel_sent(participant_id).pipe(tap(()=>{}))
    request.subscribe(
      ()=>{},
      (error)=>{}
    )
    return request
  }

}




// https://localhost:8443/home?utm_source=facebook_ads&utm_medium=use_case_page&utm_campaign=use_cases_carousel&fbclid=IwAR3LbkgUDJGVLUh7BBiG1fwncTHu2eyUnwr238uLMcdca1S3PFSipLz50Ek&h=AT1dU0ZPbP1gHYii0CMR5j9fDiq6EDWkaQlKFNT48ziFaElMDkrcr9BtqkZKjBu5pg6mCc7eV8rjjXTd5WEmvYDqZXnLVTDvnIa0HzcHlgfQuIEYjY_-R-zhnFSoRM65cNVYynpz8A&__tn__=*I&c%5B0%5D=AT0EcGQlePKHAvPPXHZ-AnefG7XOG6EJzA90wp6txB4a94easHKSp7A5yIgVlceO2rcYou7laZgfphqpJFVJ7q9KKkfCAsfV9tO60bYM2AgiMu7Xuia8zN8Dox2Wzo7cTsfGiU6EMwKUxnjnpeJ4lIxEoOoJiNz3rSJYaiNSPFoDBrVbW1gDY8zIwc-_KHagXW8DjVTbMWQkuTADS1urlmFG8g
