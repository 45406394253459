import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from './credentials.service';
import {get} from "lodash-es";
import {AuthenticationService} from "@core/authentication/authentication.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  private defaultRoute:string = ''

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationSerivce: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // USER role is UNDEFINED -> logout
    if(!!this.credentialsService.credentials && !this.credentialsService.credentials.role){
      this.authenticationSerivce.logout()
      return false;
    }

    // Route NOT PROTECTED
    if(!route.data.roles || route.data.roles.length === 0) return true
    // User AUTHENTICATED
    if(this.credentialsService.isAuthenticated()){
      // Route AUTHORIZED
      if(route.data.roles.includes(this.credentialsService.credentials.role)) return true
    }
    // Route NOT AUTHORIZED
    return this.redirectToDefault(route, state)
  }

  redirectToDefault(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    this.router.navigate(
      [this.defaultRoute],
      { queryParams: { redirect: state.url }, replaceUrl: true }
    );
    return false
  }

}
