import {Injectable, isDevMode} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Participant} from "@app/shared/models/Participant";
import {Survey} from "@app/shared/models/Survey";
import {catchError, filter, first, map, share, shareReplay, switchMap, switchMapTo, tap} from "rxjs/operators";
import {isNaN, toNumber} from "lodash-es";
import {AuthenticationService} from "@core/authentication/authentication.service";
import {Configuration} from "@shared/models/Configuration";
import {Credentials, CredentialsService} from "@core/authentication/credentials.service";


export type RequestWitProgress<T> = {percentage: number, body: T}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public participant_survey$: ReplaySubject<Survey[]>

  public participantSurveyRefresh$: BehaviorSubject<void>
  public participantSurvey$: Observable<Survey[]>

  public participant_surveyStatus: 'empty'|'loading'|'completed'|'error'
  public configuration: any
  public config$: Observable<Configuration>

  constructor(
    public client: HttpClient,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
  )
  {
    // this.config$ = new ReplaySubject<any>(1)
    this.participant_survey$ = new ReplaySubject<Survey[]>(1)
    this.participantSurveyRefresh$ = new BehaviorSubject<void>(undefined)
    this.participant_surveyStatus = 'empty'

    this.config$ = this.client.get<Configuration>(`/configuration`).pipe(
      filter((resp:any)=>!!resp),
      map((configuration: any)=>{
        return {
          ...configuration,
          limesurveyUrl: isDevMode()
            ? configuration.limesurveyUrl.replace('http://limesurvey', 'https://localhost:8843')
            : configuration.limesurveyUrl
        } as Configuration
      }),
      shareReplay(1)
    )

    this.participantSurvey$ = this.participantSurveyRefresh$.pipe(
      switchMapTo(this.credentialsService.change$),
      switchMap((credentials: Credentials)=>
        this.client.get<Survey[]>(`/limesurvey/participant/${credentials.participant_id}/survey/`)
      ),
      shareReplay(1)
    )
  }

  public get_participants_csv(): Observable<string>{
    return this.client.get('/limesurvey/participant', {
    // return this.client.get('/limesurvey/csv', {
      responseType: 'text',
      headers: {'Accept': 'text/csv'}
    })
  }

  public get_participant_survey(participant_id:number, refresh:boolean=false): Observable<Survey[]>{
    this.participant_surveyStatus = 'loading'
    let options = refresh ? {params: {refresh: 'true'}} : {}

    return this.client.get(`/limesurvey/participant/${participant_id}/survey/`, options)
      .pipe(
        tap(
          (s: Survey[])=>{
            this.participant_survey$.next(s)
            this.participant_surveyStatus = 'completed'
          }
        ),
        catchError((e: any): Observable<any> => {
          this.participant_survey$.next(e)
          this.participant_surveyStatus = 'error'
          return throwError(e)
        })
      )
  }

  public sync_participant_survey(participantID: number, surveyID: string): Observable<Survey>{
    let syncSingle$ = this.client.post(`/limesurvey/participant/${participantID}/survey/${surveyID}`, {})
        .pipe(share())
    let refresh$ = combineLatest([syncSingle$, this.refresh_participant_survey_list()])
        .pipe(map(([survey, _]: [Survey, Survey[]])=>survey))
    // Trigger the sync despite the fact that no one is listening
    syncSingle$.subscribe()
    return refresh$
  }

  public refresh_participant_survey_list(): Observable<Survey[]>{
    this.participantSurveyRefresh$.next()
    return this.participantSurvey$.pipe(first())
  }

  public get_participant_detail(participant_id:number): Observable<Participant>{
    return this.client.get(`/limesurvey/participant/${participant_id}/`) as Observable<Participant>
  }

  public activate_user(token:string){
    return this.client.get(`/auth/activation/${ token }`)
  }

  public delete_user(user_id:number){
    return this.client.delete(`/auth/user/${ user_id }`)
  }

  public get_csv(){
    return this.client.get('/limesurvey/csv', {responseType: 'text'})
  }

  public pixel_sent(participant_id: number):Observable<any>{
    return this.client.post(`/pixel_sent/${participant_id}/`, {})
  }

  isDummyEmail(email:string){
    return !!email && email.endsWith('.dummy')
  }

  isDummyName(name:string) {
    return !!name && !isNaN(toNumber(name))
  }
}
