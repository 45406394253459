import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import {NavSpacerModule} from "@app/shared/helpers/nav-spacer/nav-spacer.module";
import { FooterComponent } from './footer/footer.component';
import {FlashMessageModule} from "@shared/partials/flash-message/flash-message.module";
import {LoaderModule} from "@shared/loader/loader.module";


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NavSpacerModule,
    FlashMessageModule,
    LoaderModule
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
    FooterComponent
  ],
})
export class ShellModule {
}
